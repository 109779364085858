import React, { Component } from "react"
import { Carousel } from "react-responsive-carousel"
import ConfigContext from "./utils/configContext"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { graphql, StaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

export default class Carousel2 extends Component {
  static contextType = ConfigContext
  render() {
    const gameCat = this.context.gameCategory
    const bannerImages = graphql`
      {
        rummyBanners: allFile(
          filter: {
            extension: { regex: "/(jpg)|(jpeg)|(png)/" }
            relativeDirectory: { glob: "banners/rummy/**" }
          }
          sort: {fields: name}
        ) {
          edges {
            node {
              relativePath
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 840, breakpoints: [ 400, 600 ])
              }
            }
          }
        }
      }
    `

    return (
      <>
        <StaticQuery
          query={bannerImages}
          render={data => {
            const pageBanners = data.rummyBanners.edges
            return (
              <div>
                <Carousel
                  showThumbs={false}
                  showArrows={false}
                  showStatus={false}
                  centerMode={this.context.customCarousel ? true : false}
                  infiniteLoop={this.context.customCarousel ? false : true}
                  autoPlay
                >
                  {
                    pageBanners.map(
                      (item, i) => (
                        <div key={i} className="carousel-img">
                          <GatsbyImage image={item.node.childImageSharp.gatsbyImageData} alt={gameCat} width="400" loading="auto" />
                        </div>
                      )
                    )
                  }
                </Carousel>
              </div>
            )
          }}
        />
      </>
    )
  }
}
