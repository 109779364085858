import React from "react"
import ConfigContext from "./utils/configContext"
import ReviewsList from "./ReviewsList"
import icon from "../images/icon.svg"
import Disclaimer from "./Disclaimer"
import "../styles/landing.css"

class Description extends React.Component {
  static contextType = ConfigContext

  state = {
    showDescription: false,
    scrollHeight: 0,
    progress: 0,
    initialMount: true,
  }

  toggleShowDescription = () => {
    this.setState(state => {
      return { showDescription: !state.showDescription }
    })
  }

  async componentDidMount() {
    let self = this
    window.addEventListener("scroll", function () {
      if (self.state.initialMount && window.pageYOffset > 2) {
        self.setState(
          { scrollHeight: window.pageYOffset, initialMount: false },
          () => {
            // console.log("state is set")
          }
        )
      }
      if (window.pageYOffset > 400 && self.state.progress !== 400) {
        // setting progress
        self.setState({ progress: 400 })
      }
      if (window.pageYOffset < 400 && self.state.progress !== 0) {
        // unsetting progress
        self.setState({ progress: 0 })
      }
    })
  }

  nonChromeFunc = () => {
    this.props.toggleModal()
    setTimeout(() => {
      window.location = this.props.installUrl
    }, 1100)
  }

  onClickHandler = eventSource => {
    this.props.fbEventAndShowModal(eventSource)
    if (this.context.customDownloadLinks) {
      window.location = this.props.installUrl
    } else {
      this.props.isChrome ? this.props.togglePrompt() : this.nonChromeFunc()
    }
  }

  render() {
    let { showDescription } = this.state
    const readMoreText = this.context.readMoreText || "READ MORE"
    const collapseText = this.context.collapseText || "COLLAPSE"

    let { scrollHeight, progress } = this.state
    let { reviewsVisible } = this.props
    let transitionClass = progress >= 400 ? "fade-in" : "fade-out"
    return (
      <>
        <div className="description-list">
          {this.context.phonepePage && (
            <div className="description-list-text phonepe-text">
              <h6>Phonepe Offer:</h6>
              <ul>
                <li>1000% Bonus cashback deal</li>
                <li>Offer valid on minimal deposit of Rs 100</li>
                <li>Offer is valid only one time per user</li>
                <li>Offer is valid for new users only</li>
              </ul>
              <h6>Offer Duration:</h6>
              <ul>
                <li>Offer is valid till 30th September 2022</li>
              </ul>
              <h6>How to Avail the offer?</h6>
              <ul>
                <li>Click on Download App</li>
                <li>
                  Click on "wallet" icon on homescreen and navigate to "Add
                  Cash"
                </li>
                <li>
                  Apply the promocode while adding cash and the bonus will be
                  credited to your account.
                </li>
              </ul>
              <h6>Terms &amp; Conditions</h6>
              <p>
                PhonePe will not be liable for application of coupon code on
                merchant platform Offers &amp; benefits cannot be clubbed with
                existing offers, promotions &amp; discount. In case of any
                issues, please reach out at contact@getmega.com
              </p>
              <br />
            </div>
          )}
          <div className="description-list-text">
            {this.context.descriptionText}
            {!showDescription ? (
              <div className="toggle-button">
                <span
                  onClick={this.toggleShowDescription}
                  onKeyDown={this.toggleShowDescription}
                  role="button"
                  tabIndex="0"
                  className="is-pointer"
                >
                  {readMoreText}
                </span>
              </div>
            ) : null}
          </div>

          {showDescription ? (
            <>
              <div className="description-list-text">
                {!this.context.hasLargeDescription ? (
                  <ul>
                    {[
                      ...(this.context.descriptionList || []),
                      ...(this.context.variantDescriptionList || []),
                    ].map((item, i) => (
                      <li key={i}>{item}</li>
                    ))}
                  </ul>
                ) : (
                  <div
                    className="description-large-text"
                    dangerouslySetInnerHTML={{
                      __html: this.context.largeDescription,
                    }}
                  />
                )}
                <p className="is-size-7">
                  This game follows standard {this.context.gameCategory} rules
                  and offers a gameplay experience identical to real{" "}
                  {this.context.gameCategory} gameplay.
                </p>
                <Disclaimer />
              </div>
              <div className="toggle-button">
                <span
                  onClick={this.toggleShowDescription}
                  onKeyDown={this.toggleShowDescription}
                  role="button"
                  tabIndex="0"
                  className="is-pointer"
                >
                  {collapseText}
                </span>
              </div>
            </>
          ) : null}
        </div>
        {this.context.showAppDetails && (
          <div className="app-details">
            <table>
              <tbody>
                <tr>
                  <td>
                    <span>Version</span>
                    {this.context.appVersion || "7.0.5"}
                  </td>

                  <td>
                    <span>Updated on</span>
                    {this.context.latestRelease || "19 Sep 2022"}
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Required Android</span>6.0 and above
                  </td>
                  <td>
                    <span>Downloads</span>
                    {this.context.numOfDownloads}
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Download size</span>
                    {this.context.appSize}
                  </td>
                  <td>
                    <span>Released on</span>19 Sep 2022
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Content rating</span>18+
                  </td>
                  <td>
                    <span>Offered by</span>Mega{" "}
                    {this.context.gameName === "rummy" ? "Rummy" : "Poker"}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        <>
          <ReviewsList
            reviewsVisible={reviewsVisible}
            scrollHeight={scrollHeight}
            data={this.props.data}
            gameName={this.context.staticReviews}
          />
          <div className={`sticky-container ${transitionClass}`}>
            <div className={`download glossy-button`}>
              <button
                onClick={() => {
                  this.onClickHandler("Download App Button")
                }}
                type="button"
              >
                <div className="icon-flex">
                  <img src={icon} alt="" />
                </div>
                <div
                  className="download-text font-download fontGotham"
                  data-link={this.props.installUrl}
                >
                  Download App
                </div>
              </button>

              <i />
            </div>
          </div>
        </>
      </>
    )
  }
}

export default Description
