import React from "react"

class CompareFeatures extends React.Component {
  state = {
    isShowAll: false,
  }
  toggleSeeAllComparisons = () => {
    this.setState(state => ({ isShowAll: !state.isShowAll }))
    const toggleText = this.state.isShowAll ? "All" : "Less"
    const toggleShow = this.state.isShowAll ? "show-less" : "show-all"
    this.setState({ toggleTextValue: toggleText })
    this.setState({ toggleShowClass: toggleShow })
  }
  render() {
    let { toggleTextValue, toggleShowClass } = this.state
    const FeaturesToggleButton = (
      <>
        <div className="feature-list-all">
          <span
            onClick={this.toggleSeeAllComparisons}
            onKeyDown={this.toggleSeeAllComparisons}
            role="button"
            tabIndex="0"
          >
            See {toggleTextValue || "All"} Features
          </span>
        </div>
      </>
    )
    return (
      <div className="feature-section">
        <div className="features-box">
          <h3 className="f-title">Why Choose Mega</h3>
          <div className="feature-logos">
            <div className="logo-item">
              <img
                src={this.props.competitorImage}
                width="60"
                height="60"
                alt={this.props.competitorName}
              />
              <span>{this.props.competitorName}</span>
            </div>
            <div className="logo-item">
              <img
                src={this.props.megaImage}
                width="60"
                height="60"
                alt="Mega"
              />
              <span>{this.props.megaName}</span>
            </div>
          </div>

          <div className={`feature-list-wrap ${toggleShowClass}`}>
            {this.props.content.map(item => (
              <div key={item.id} className="feature-list-item">
                <div className="feature-title">{item.category}</div>
                <div className="feature-items">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: item.competitorText,
                    }}
                  />
                  <span
                    dangerouslySetInnerHTML={{
                      __html: item.megaText,
                    }}
                  />
                </div>
              </div>
            ))}

            {!this.props.hideToggleButton ? FeaturesToggleButton : null}
          </div>
        </div>
      </div>
    )
  }
}
export default CompareFeatures
