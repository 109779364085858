import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import AuthLogin from "./GetOtp/authLogin"

const Data = graphql`
  {
    getMegaLogo: file(relativePath: { eq: "getMegaLogo.png" }) {
      childImageSharp {
        gatsbyImageData(height: 30, layout: CONSTRAINED)
      }
    }
    player: file(relativePath: { eq: "player.png" }) {
      childImageSharp {
        gatsbyImageData(height: 40, layout: CONSTRAINED)
      }
    }
    safe: file(relativePath: { eq: "safe.png" }) {
      childImageSharp {
        gatsbyImageData(height: 40, layout: CONSTRAINED)
      }
    }
    support: file(relativePath: { eq: "support.png" }) {
      childImageSharp {
        gatsbyImageData(height: 40, layout: CONSTRAINED)
      }
    }
    megaBanner: file(relativePath: { eq: "newmegaBanner.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    feature1: file(relativePath: { eq: "feature1.png" }) {
      childImageSharp {
        gatsbyImageData(height: 86, layout: CONSTRAINED)
      }
    }
    feature2: file(relativePath: { eq: "feature2.png" }) {
      childImageSharp {
        gatsbyImageData(height: 73, layout: CONSTRAINED)
      }
    }
    feature3: file(relativePath: { eq: "feature3.png" }) {
      childImageSharp {
        gatsbyImageData(height: 93, layout: CONSTRAINED)
      }
    }
  }
`

class MegaContent extends React.Component {
  render() {
    return (
      <StaticQuery
        query={Data}
        render={data => {
          return (
            <div className="app-container">
              <div className="main-screen">
                <header className="headerBlock">
                  <div className="container">
                    <Link to="/">
                      <GatsbyImage
                        image={data.getMegaLogo.childImageSharp.gatsbyImageData}
                        alt="GetMega"
                      />
                    </Link>
                    <div className="headerRight">
                      <div className="headerBox">
                        <GatsbyImage
                          image={data.player.childImageSharp.gatsbyImageData}
                          alt="GetMega"
                        />
                        <p>
                          <span>50 Lac+</span>
                          Genuine Players
                        </p>
                      </div>
                      <div className="headerBox">
                        <GatsbyImage
                          image={data.safe.childImageSharp.gatsbyImageData}
                          alt="GetMega"
                        />
                        <p>
                          <span>100%</span>
                          Safe & Secure
                        </p>
                      </div>
                      <div className="headerBox">
                        <GatsbyImage
                          image={data.support.childImageSharp.gatsbyImageData}
                          alt="GetMega"
                        />
                        <p>
                          <span>24X7</span>
                          Customer Support
                        </p>
                      </div>
                    </div>
                  </div>
                </header>
                <div className="sliderBlock">
                  <AuthLogin />
                  <div className="sliderImg">
                    <GatsbyImage
                      image={data.megaBanner.childImageSharp.gatsbyImageData}
                      alt="GetMega"
                    />
                  </div>
                </div>
                <div className="contentBlock">
                  <div className="container">
                    <div className="introArea">
                      <h1>India’s Largest Gaming Platform</h1>
                      <div className="row">
                        <div className="col3">
                          <div className="featureIcon">
                            <GatsbyImage
                              image={
                                data.feature1.childImageSharp.gatsbyImageData
                              }
                              alt="GetMega"
                            />
                          </div>
                          <div className="featureTitle">
                            100% Safe & secured
                          </div>
                        </div>
                        <div className="col3">
                          <div className="featureIcon">
                            <GatsbyImage
                              image={
                                data.feature2.childImageSharp.gatsbyImageData
                              }
                              alt="GetMega"
                            />
                          </div>
                          <div className="featureTitle">
                            Best gaming experience
                          </div>
                        </div>
                        <div className="col3">
                          <div className="featureIcon">
                            <GatsbyImage
                              image={
                                data.feature3.childImageSharp.gatsbyImageData
                              }
                              alt="GetMega"
                            />
                          </div>
                          <div className="featureTitle">
                            24/7 customer support
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="introArea">
                      <h3>Why Choose Us?</h3>
                      <div className="row">
                        <div className="col3">
                          <div className="featureSubTitle">
                            Cross-Platform Compatibility
                          </div>
                          <p>
                            We know the importance of gaming flexibility,
                            GetMega offers seamless cross-platform
                            compatibility. Our platform offers a consistent
                            gaming experience across all devices, making it
                            accessible and convenient.
                          </p>
                        </div>
                        <div className="col3">
                          <div className="featureSubTitle">24*7 Rewards </div>
                          <p>
                            GetMega stands out by offering around-the-clock
                            rewards for all players. This reward system allows
                            all players to earn bonuses regardless of schedules.
                          </p>
                        </div>
                        <div className="col3">
                          <div className="featureSubTitle">
                            Instant Withdrawals
                          </div>
                          <p>
                            Recognizing the importance of quick and efficient
                            transactions, GetMega boasts the fastest withdrawal
                            system. Players can enjoy the fruits of their
                            success without delays, contributing to a positive
                            and hassle-free gaming experience.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <footer className="footerBlock">
                  <div className="container">
                    <p>
                      © 2024 Megashots Internet Private Limited | All Rights
                      Reserved
                    </p>
                    <p>
                      This game involves an element of financial risk and may be
                      addictive. Please play responsibly and at your own risk.
                    </p>
                    <p>
                      Players from Meghalaya, Andhra Pradesh, Telangana, Assam,
                      Odisha, Nagaland, and Sikkim are not allowed to play
                      online cash rummy.
                    </p>
                    <p>
                      <span>
                        Disclaimer: This card game is the biggest online card
                        gaming app in India with different variants of cards and
                        zero waiting period. GetMega is only available for
                        people who are above 18 years of age. GetMega is
                        available only in those Indian states where skill gaming
                        is allowed by regulations. Megashots Internet Pvt. Ltd.
                        is the sole owner of and reserves the right to “Mega”
                        trademark, logos, assets, content, information, etc.
                      </span>
                    </p>
                  </div>
                </footer>
              </div>
            </div>
          )
        }}
      />
    )
  }
}

export default MegaContent
