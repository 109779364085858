import React from 'react';
import '../styles/dynamicStar.css'

const DynamicStar = ({ value }) => {
  return (
    <div className="ratings-dynamic">
      <div className="empty-stars"/>
      <div className="full-stars" style={{ width: `${value}%` }}/>
    </div>
  )
}

export default DynamicStar
