import React, { useContext } from "react"
import ConfigContext from "./utils/configContext"

const Disclaimer = () => {
  const context = useContext(ConfigContext)

  return (
    <div>
      <p className="is-size-7">
        {context.disclaimerText ||
          "This game involves an element of financial risk and may be addictive. Please play responsibly and at your own risk."}
      </p>
    </div>
  )
}

export default Disclaimer
