import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Button, makeStyles } from "@material-ui/core"
import { isMobile } from "react-device-detect"
import OTPInput from "./otpInput"
import timer from "../../images/timer.svg"

const useStyles = makeStyles(theme => ({
  otpContainer: {
    display: "grid",
    alignItems: "center",
    gridTemplateRows: "4rem 1fr 6rem",
    maxHeight: "100%",
    justifyContent: "center",
  },
  otpHelperText: {
    fontFamily: "Gotham Rounded Medium !important",
    fontSize: "1.0rem",
    color: "#666666",
    whiteSpace: "normal",
    wordBreak: "break-word",
    [theme.breakpoints.down("sm")]: {
      fontSize: ".8rem",
    },
  },
  otpHelperHighlightedText: {
    fontFamily: "GothamRnd-Book",
    fontSize: "1.125rem",
    fontWeight: 700,
    color: "#000000",
    paddingLeft: "0.25rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  enterOTPHelperText: {
    fontFamily: "GothamRnd-Book",
    fontSize: "1.125rem",
    fontWeight: 500,
    color: "#000000",
  },
  enabledSubmitButton: {
    "&:hover": {
      background: "#0166FF 0% 0% no-repeat padding-box",
    },
  },
  disabledSubmitButton: {
    background: "#6c757d 0% 0% no-repeat padding-box",
    opacity: ".35",
    color: "#efefef",
    "&:hover": {
      background: "#6c757d 0% 0% no-repeat padding-box",
    },
  },
  helperTextContainer: {
    margin: "0 0.5rem 0 0.5rem",
    textAlign: "center",
  },
}))

const host = "https://platform-api.tajnetwork.com/v1/"

const isNullOrEmpty = value => {
  if (value === null || value === undefined || value === "") return true

  return false
}

const callApi = ({ url, method, headers, body }) => {
  console.log(body, "888888888889888880000")
  return new Promise((resolve, reject) => {
    fetch(host + url, {
      method,
      headers,
      body: JSON.stringify(body),
    })
      .then(response => {
        return response.json()
      })
      .then(data => {
        console.log("Data:", data)
        if (data?.errormessage) {
          throw new Error(data?.errormessage)
        }
        resolve(data)
      })
      .catch(error => {
        console.error("Error:", error)
        reject(error)
      })
  })
}

//login otp Flow..........
const OTPLogin = props => {
  const classes = useStyles()
  const uname = props?.mobileNumber
  const [isOTPCleared, setIsOTPCleared] = useState(0)
  const [otp, setOtp] = useState("")
  const [otpToken, setOtpToken] = useState(props.token)
  const [submitted, setSubmitted] = useState(false)
  const [resendEnabled, setResendEnabled] = useState(false)
  const [remainingTime, setRemainingTime] = React.useState(30)
  let intervalRef = useRef()
  const [error, setError] = useState()

  const onHandleChangeOTP = useCallback(otp => {
    console.log("onHandleChangeOTP", otp, "656555")
    if (otp === "") {
      setError()
    }
    setOtp(otp)
  }, [])

  const handleSubmitOTP = useCallback(() => {
    setSubmitted(true)

    if (!isNullOrEmpty(otp) && otp.length === 6) {
      let authData = JSON.parse(localStorage.getItem("authData"))
      const event = props.isRegister
        ? "player_mobile_otp_registration"
        : "player_mobile_otp_auth"
      callApi({
        url: `${props.isRegister ? "register" : "auth"}/player`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${authData?.access_token}`,
        },
        body: {
          // event: "player_mobile_otp_auth",
          event: event,
          params: {
            mobile_number: props.mobileNumber,
            mobile_otp: otp,
            mobile_otp_token: otpToken,
            device_detail: {
              device_type: "Web",
              client_type: "WebUI",
              os_family: "OS",
            },
            location: { latitude: "0", longitude: "0" },
          },
        },
      })
        .then(data => {
          localStorage.setItem("authData", JSON.stringify(data))
          window.open("https://getmega.onelink.me/shOD/PokerPseudo", "_self")
        })
        .catch(error => {
          console.log(error)
          setError(error?.message)
        })
    } else setError("Please enter a valid OTP")
  }, [otp, uname, otpToken, error])

  const handleResendOTP = useCallback(() => {
    if (resendEnabled === false) {
      return
    }
    clearInterval(intervalRef.current)
    setRemainingTime(30)
    setResendEnabled(false)
    setSubmitted(false)
    setIsOTPCleared(prev => prev + 1)
    let authData = JSON.parse(localStorage.getItem("authData"))
    callApi({
      url: "auth/player?mobile=" + uname,
      method: "PUT",
      headers: {
        Authorization: `Bearer ${authData?.access_token}`,
      },
      body: {
        event: "player_auth_resend_otp",
        params: {
          mobile_otp_token: otpToken,
        },
      },
    })
      .then(data => {
        setOtpToken(data?.otp_token)
      })
      .catch(error => {
        console.log(error)
        setError(false)
      })
  }, [uname, setIsOTPCleared, resendEnabled, otpToken, error])

  const renderOtpScreen = useMemo(() => {
    return (
      <>
        <h4>Verify Mobile</h4>
        <p>
          OTP has been sent to <br /> +91- {uname}
        </p>

        <div className="input-group">
          <OTPInput
            isNumberInput
            length={6}
            onEnterOTP={handleSubmitOTP}
            isCleared={isOTPCleared}
            className="otpInputClass"
            inputClassName="otpInput"
            onChangeOTP={otp => onHandleChangeOTP(otp)}
          />
          {error && <span className="error">{error}</span>}
        </div>
        <div
          className="sendOtpHelper"
          onClick={handleResendOTP}
          onKeyDown={e => {
            if (e.key === "Enter") {
              handleResendOTP()
            }
          }}
          role="button"
          tabIndex={0}
        >
          {!resendEnabled ? (
            <>
              <span>
                <img src={timer} alt="Timer" width="18" height="18" />
                {remainingTime}
              </span>
              <div className="resendOTPText">Resend OTP</div>
            </>
          ) : (
            <p className={resendEnabled ? "enabled" : "resendOTPText"}>
              Resend OTP
            </p>
          )}
        </div>
        <Button
          className={
            !isNullOrEmpty(otp) && otp.length === 6 ? "enable" : "disable"
          }
          disabled={!isNullOrEmpty(otp) && otp.length === 6 ? false : true}
          onClick={
            !isNullOrEmpty(otp) && otp.length === 6 ? handleSubmitOTP : () => {}
          }
        >
          Verify OTP
        </Button>
      </>
    )
  }, [isOTPCleared, uname, resendEnabled, remainingTime, otp])

  useEffect(() => {
    console.log(resendEnabled, "88886668899")
    if (resendEnabled === false) {
      handleTimer(30)
    } else {
      clearInterval(intervalRef.current)
    }
  }, [resendEnabled])

  useEffect(() => {
    return () => clearInterval(intervalRef.current)
  }, [])

  const handleTimer = async timer => {
    intervalRef.current = setInterval(function () {
      console.log(timer, "99993322")
      if (--timer <= 0) {
        setResendEnabled(true)
      } else {
        setRemainingTime(timer)
      }
    }, 1000)
    return () => clearInterval(intervalRef.current)
  }

  return <div className="otpWrapper">{renderOtpScreen}</div>
}

//Authorization Flow..........
const AuthLogin = props => {
  const [validateMobileVisibility, setValidateMobileVisibility] = useState({
    display: "none",
    color: "red",
  })
  const [validated, setValidated] = useState(false)
  const [mobileNumber, setMobileNumber] = useState("")
  const [otpSentSuccess, setOtpSentSuccess] = useState(false)
  const [token, setToken] = useState()
  const [isRegister, setIsRegister] = useState(true)
  const [isChecked, setIsChecked] = useState(false)
  const [error, setError] = useState()

  const handleLogin = () => {
    onOTPLogin()
  }

  useEffect(() => {
    callApi({
      url: "auth/device",
      method: "GET",
      headers: {
        "x-api-key": "Z2V0bWVnYXBva2VyLXdlYi1hdXRo:MXBTwqM3Myp+fEEmQGtkZCI=",
      },
    })
      .then(data => {
        localStorage.setItem("authData", JSON.stringify(data))
      })
      .catch(error => {
        console.log(error)
        setError(error?.message)
      })
  }, [])

  const onOTPLogin = useCallback(() => {
    setError()
    if (!isNullOrEmpty(mobileNumber)) {
      let authData = JSON.parse(localStorage.getItem("authData"))
      callApi({
        url:
          `${isRegister ? "register" : "auth"}/player?mobile=` + mobileNumber,
        method: "GET",
        headers: {
          Authorization: `Bearer ${authData?.access_token}`,
        },
      })
        .then(data => {
          setToken(data.otp_token)
          setOtpSentSuccess(true)
        })
        .catch(error => {
          console.log(error)
          setError(error?.message)
        })
      setValidated(true)
      return
    } else {
      setValidated(true)
      setError("Please Enter correct Mobile Number")
    }
  }, [mobileNumber, isRegister, error])

  if (otpSentSuccess) {
    return (
      <div className="smsFormArea">
        <div className="smsFormWrap">
          <OTPLogin
            onCloseCallback={() => {}}
            mobileNumber={mobileNumber}
            token={token}
            isRegister={isRegister}
          />
          <p>
            <span
              onClick={() => {
                setIsRegister(true)
                setOtpSentSuccess(false)
              }}
              onKeyDown={e => {
                if (e.key === "Enter") {
                  setIsRegister(true)
                  setOtpSentSuccess(false)
                }
              }}
              role="button"
              tabIndex={0}
            >
              Cancel
            </span>
          </p>
        </div>
      </div>
    )
  }

  return (
    <div className="smsFormArea">
      <div className="smsFormWrap">
        <>
          {isRegister ? (
            <div className="smsRegister">
              <h4>Sign Up & Get 1000 Bonus*</h4>
              <div
                className="input-group numhide"
                style={isMobile ? { display: "none" } : {}}
              >
                <input
                  id="telNo"
                  name="telNo"
                  type="text"
                  min={9}
                  max={10}
                  className="form-control form-control-lg mobile-number1 bg-gray-input"
                  autoComplete="off"
                  placeholder="Enter Mobile no"
                  onChange={event => {
                    console.log(
                      event.target.value,
                      "454555",
                      event.target.value
                    )
                    let mobileNumber = event.target.value.replaceAll("-", "")
                    if (mobileNumber.length > 10) {
                      return
                    }
                    if (mobileNumber.length === 10) {
                      setValidateMobileVisibility({
                        display: "none",
                        color: "red",
                      })
                    }
                    setValidated(false)
                    setMobileNumber(mobileNumber)
                  }}
                  value={mobileNumber.replace(
                    /^(\d{3})(\d{3})(\d{4})/,
                    "$1-$2-$3"
                  )}
                  onKeyDown={event => {
                    if (event.key === "Enter") {
                      handleLogin()
                    }
                  }}
                />
                {error && <span className="error">{error}</span>}
              </div>
              <div className="refCode">Enter Sign Up/Referral Code?</div>
              <div className="checkField">
                <label>
                  <div className="customCheck">
                    <input
                      type="checkbox"
                      name="terms"
                      required
                      checked={isChecked}
                      onChange={e => setIsChecked(e.target.checked)}
                    />
                    <div className="check" />
                  </div>
                  I certify that I am 18 years old and I agree to the T&Cs.
                </label>
              </div>
              <Button
                onClick={handleLogin}
                disabled={!isChecked}
                className={isChecked ? "enable" : "disable"}
              >
                Get OTP
              </Button>
              <p>
                Already have an account?
                <span
                  onClick={() => {
                    setIsRegister(false)
                    setError(false)
                  }}
                  onKeyDown={e => {
                    if (e.key === "Enter") {
                      setIsRegister(false)
                      setError(false)
                    }
                  }}
                  role="button"
                  tabIndex={0}
                >
                  Login Now
                </span>
              </p>
            </div>
          ) : (
            <div className="smsLogin">
              <h4>Login Now</h4>
              <div
                className="input-group numhide"
                style={isMobile ? { display: "none" } : {}}
              >
                <input
                  id="telNo"
                  name="telNo"
                  type="text"
                  min={9}
                  max={10}
                  className="form-control form-control-lg mobile-number1 bg-gray-input"
                  autoComplete="off"
                  placeholder="Enter Mobile no"
                  onChange={event => {
                    console.log(
                      event.target.value,
                      "454555",
                      event.target.value
                    )
                    let mobileNumber = event.target.value.replaceAll("-", "")
                    if (mobileNumber.length > 10) {
                      return
                    }
                    if (mobileNumber.length === 10) {
                      setValidateMobileVisibility({
                        display: "none",
                        color: "red",
                      })
                    }
                    setValidated(false)
                    setMobileNumber(mobileNumber)
                  }}
                  value={mobileNumber.replace(
                    /^(\d{3})(\d{3})(\d{4})/,
                    "$1-$2-$3"
                  )}
                  onKeyDown={event => {
                    if (event.key === "Enter") {
                      handleLogin()
                    }
                  }}
                />
                {error && <span className="error">{error}</span>}
              </div>
              <Button onClick={handleLogin}>Get OTP</Button>
              <p>
                New to GetMega?
                <span
                  onClick={() => {
                    setIsRegister(true)
                    setError(false)
                  }}
                  onKeyDown={e => {
                    if (e.key === "Enter") {
                      setIsRegister(true)
                      setError(false)
                    }
                  }}
                  role="button"
                  tabIndex={0}
                >
                  SignUp Now
                </span>
              </p>
            </div>
          )}
        </>
      </div>
    </div>
  )
}

export default AuthLogin
