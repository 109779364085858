import React from "react"
import { getMobileOperatingSystem } from "./utils/generateUrl"
import Popup from "./Popup"
import Contents from "./Contents"
import ConfigContext from "./utils/configContext"
import DownloadPrompt from "./DownloadPrompt"
import "../styles/shiny.css"
import "../styles/App.css"
import "../styles/modal.css"
import Step1 from "../images/prompt/step1-rummy.png"
import Step2 from "../images/prompt/step2.png"
import Step3 from "../images/prompt/step3.png"
import Step4 from "../images/prompt/step4-rummy.png"
import MegaContent from "./megaContent"

class App extends React.Component {
  static contextType = ConfigContext
  state = {
    initialMount: true,
    featuresVisible: false,
    progress: 0,
    isFreeGemsPopupOpen: false,
    isOpen: false,
    showPrompt: false,
    chromeBrowser: false,
  }

  eventTracker = eventSource => {
    window?.fbq?.("trackCustom", "downloadButtonClicked", {
      click_source: eventSource,
    })
    window?.gtag?.("event", "Download APK", {
      event_category: "Downloaded",
      event_label: eventSource,
      value: 1,
    })
    window?.trackGLDownloadEvent?.()
    window?.gtag_report_conversion?.()
  }

  componentDidMount() {
    const customDownloadUrls =
      getMobileOperatingSystem() === "iOS"
        ? this.context.appstoreLink
        : this.context.playstoreLink
    const downloadLink = this.context.customDownloadLinks
      ? customDownloadUrls
      : this.context.installUrl
    // generate onelink url
    this.setState({
      installUrl: downloadLink,
    })

    let self = this
    window.onscroll = function () {
      if (self.state.initialMount && window.pageYOffset > 2) {
        self.setState({ featuresVisible: true, initialMount: false })
      }
    }

    // open free gems popup after 5 seconds
    const freeGemsTimer = setTimeout(() => {
      this.setState({ isFreeGemsPopupOpen: true })
    }, 5 * 1000)
    this.setState({ freeGemsTimer })
  }

  componentWillUnmount() {
    clearTimeout(this.state.freeGemsTimer)
  }

  closeFreeGemsPopup = () => {
    this.setState({ isFreeGemsPopupOpen: false })
  }

  toggleModal = () => {
    this.setState(state => ({ isOpen: !state.isOpen }))
  }

  togglePrompt = () => {
    this.setState(state => ({ showPrompt: !state.showPrompt }))
  }

  promptCallback = () => {
    this.togglePrompt()
    this.toggleModal()
  }

  render() {
    let { installUrl, isFreeGemsPopupOpen, chromeBrowser } = this.state

    return (
      <>
        {this.state.showPrompt ? (
          <DownloadPrompt
            togglePrompt={this.togglePrompt}
            installUrl={installUrl}
            callback={this.promptCallback}
          />
        ) : null}

        {this.state.isOpen ? (
          <div className="custom-modal video-modal">
            {this.context.gameCategory === "rummy" ? (
              <div className="steps-container">
                <div className="steps-heading">
                  How to install Mega Rummy app?
                </div>
                <div className="steps-item">
                  <div className="step-title">step 1</div>
                  <div className="step-content">
                    <img src={Step1} alt="step1" />
                    <div className="step-text">
                      Click the <strong>Download anyway</strong> button
                    </div>
                  </div>
                </div>
                <div className="steps-item">
                  <div className="step-title">step 2</div>
                  <div className="step-content">
                    <img src={Step2} alt="step1" />
                    <div className="step-text">
                      Click <strong>Open</strong>, on File downloaded, and click
                      on <strong>Settings</strong>
                    </div>
                  </div>
                </div>
                <div className="steps-item">
                  <div className="step-title">step 3</div>
                  <div className="step-content">
                    <img src={Step3} alt="step1" />
                    <div className="step-text">
                      Tap on <strong>Allow from this source</strong>
                      and click on the mobile back button.
                    </div>
                  </div>
                </div>
                <div className="steps-item">
                  <div className="step-title">step 4</div>
                  <div className="step-content">
                    <img src={Step4} alt="step1" />
                    <div className="step-text">
                      Click on <strong>Install</strong> and start playing
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <iframe
                src={`https://player.vimeo.com/video/${this.context.vimeoVideoId}?title=0&byline=0&portrait=0&autoplay=1&loop=1&background=1`}
                width="640"
                height="1138"
                frameBorder="0"
                allowFullScreen={true}
                title="install video"
              />
            )}
            <span
              className="is-large"
              onClick={this.toggleModal}
              onKeyDown={this.toggleModal}
              role="button"
              tabIndex="0"
            >
              <strong className="mdi closeBtn">X</strong>
            </span>
          </div>
        ) : null}

        {this.context.gameCategory === "mega" ? (
          <MegaContent />
        ) : (
          <Contents
            togglePrompt={this.togglePrompt}
            toggleModal={this.toggleModal}
            fbEventAndShowModal={this.eventTracker}
            isChrome={chromeBrowser}
            installUrl={installUrl}
          />
        )}

        {isFreeGemsPopupOpen &&
          !this.context.gameCategory === "mega" &&
          !this.state.isOpen &&
          !this.state.showPrompt && (
            <Popup
              closeFreeGemsPopup={this.closeFreeGemsPopup}
              togglePrompt={this.togglePrompt}
              toggleModal={this.toggleModal}
              isChrome={chromeBrowser}
              installUrl={installUrl}
              fbEventAndShowModal={this.eventTracker}
            />
          )}
      </>
    )
  }
}

export default App
