import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import CompareFeatures from "./CompareFeatures"
import stars from "../images/star.svg"
import icon from "../images/icon.svg"
import Carousel2 from "./Carousel2"
import Description from "./Description"
import ConfigContext from "./utils/configContext"

const Data = graphql`
  {
    partners: file(relativePath: { eq: "payment partners.png" }) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    rummyLogo: file(relativePath: { eq: "megarummy_logo.png" }) {
      childImageSharp {
        gatsbyImageData(height: 50, layout: CONSTRAINED)
      }
    }
  }
`
class Contents extends React.Component {
  static contextType = ConfigContext

  state = {
    featuresVisible: false,
    compName: "",
    bannerOrder: "",
  }

  componentDidMount() {
    // check for utm_comp in url
    const siteurl = new URL(window.location.href)
    const checkComp = siteurl.searchParams.get("utm_comp")
    if (checkComp) {
      this.setState({ compName: checkComp })
    }
    const getBannerOrder = siteurl.searchParams.get("ad_order")
    if (getBannerOrder) {
      this.setState({ bannerOrder: getBannerOrder })
    }
  }

  nonChromeFunc = () => {
    this.props.toggleModal()
    setTimeout(() => {
      window.location = this.props.installUrl
    }, 1100)
  }

  onClickHandler = eventSource => {
    this.props.fbEventAndShowModal(eventSource)
    if (this.context.customDownloadLinks) {
      window.location = this.props.installUrl
    } else {
      this.props.isChrome ? this.props.togglePrompt() : this.nonChromeFunc()
    }
  }

  render() {
    let {
      featuresVisible,
      compName,
      competitorName,
      megaName,
      content,
      competitorImage,
      megaImage,
      hideToggleButton = false,
    } = this.state
    const Stats = (
      <div className="stats">
        <div className="rating">
          <div className="rating-num font-stats align-row-center marBot3 fontGotham">
            {this.context.ratingCount || "4.8"}
          </div>
          <div className="rating-star">
            <img src={stars} alt=" " />
          </div>
          <div className="rating-label marneg5 font-text align-row-center fontGotham">
            Ratings
          </div>
        </div>
        <div className="players">
          <div className="players-num font-stats marBot3 align-row-center fontGotham">
            {this.context.totalPlayerCount || "3.5"}
          </div>
          <div className="players-lakh font-text align-row-center height16 fontGotham">
            {this.context.totalPlayerCountIn || "Lakh"}
          </div>
          <div className="players-players font-text mar fontGotham">
            Players
          </div>
        </div>
        <div className="prizes">
          <div className="prizes-numb font-stats marBot3 align-row-center fontGotham">
            {"18+"}
          </div>
          <div className="prizes-text1 font-text height16 fontGotham">
            Rated for
          </div>
          <div className="prizes-text2 font-text mar1 align-row-center fontGotham">
            18+ years
          </div>
        </div>
      </div>
    )

    const DownloadButton = (
      <div className="download glossy-button">
        <button
          onClick={() => {
            this.onClickHandler("Download Button")
          }}
          type="button"
        >
          <div className="icon-flex">
            <img src={icon} alt="" />
          </div>
          <div
            className="download-text font-download fontGotham"
            data-link={this.props.installUrl}
          >
            {this.context.downloadButtonText || "Download Now"}
          </div>
        </button>

        <i />
      </div>
    )

    const DownloadButtonPhonepe = (
      <div className="phonepe-download">
        <button
          onClick={() => {
            this.onClickHandler("Download Button")
          }}
          type="button"
        >
          download
        </button>
      </div>
    )

    let customContainer = ""

    if (this.context.phonepePage) {
      customContainer = "phonepe-container"
    } else if (this.context.customCarousel) {
      customContainer = "carousel-v2-container"
    }

    return (
      <StaticQuery
        query={Data}
        render={data => {
          if (compName) {
            const getAllPosts = data.allGhostPost.edges
            const currentPost = getAllPosts.filter(
              item => item.node.slug === compName
            )
            if (typeof currentPost !== "undefined" && currentPost.length > 0) {
              const getTable = currentPost.map(function (i) {
                return i.node.html
              })
              competitorName = currentPost.map(function (i) {
                return i.node.title
              })
              megaName = "Mega"

              const tableContent = document.createElement("div")

              tableContent.innerHTML = getTable[0]

              const table = tableContent?.querySelectorAll("table")[0]

              if (table) {
                // setting content
                const imageTds = table.getElementsByTagName("td")
                const compImage = imageTds[1]
                const getmegaImage = imageTds[2]
                if (compImage) {
                  competitorImage = compImage.lastChild.src
                }
                if (getmegaImage) {
                  megaImage = getmegaImage.lastChild.src
                }

                const rows = table.getElementsByTagName("tr")
                if (rows.length < 6) {
                  hideToggleButton = true
                }
                content = []

                for (let i = 2; i < rows.length; i += 1) {
                  const tds = rows[i].getElementsByTagName("td")
                  content.push({
                    id: i,
                    category: tds[0].textContent,
                    competitorText: tds[1].innerHTML,
                    megaText: tds[2].innerHTML,
                  })
                }
              }
            }
          }

          return (
            <div className={`app-container ${customContainer}`}>
              <div className="main-screen">
                {!this.context.phonepePage && (
                  <>
                    {this.context.imageLogo ? (
                      <div className="image-logo">
                        <GatsbyImage
                          image={data.rummyLogo.childImageSharp.gatsbyImageData}
                          alt={`Mega ${this.context.gameName}`}
                        />
                      </div>
                    ) : (
                      <h1 className="text-logo">
                        Mega <span>{this.context.gameName}</span>
                      </h1>
                    )}
                  </>
                )}
                <div className="image-container">
                  {this.context.phonepePage && DownloadButtonPhonepe}
                  <Carousel2 bannerOrder={this.state.bannerOrder} />
                </div>

                {this.state.compName && content ? (
                  <CompareFeatures
                    hideToggleButton={hideToggleButton}
                    competitorImage={competitorImage}
                    competitorName={competitorName}
                    megaImage={megaImage}
                    megaName={megaName}
                    content={content}
                  />
                ) : null}

                <div className="legend">
                  {Stats}
                  {DownloadButton}
                </div>
              </div>
              <Description
                data={data}
                fbEventAndShowModal={this.props.fbEventAndShowModal}
                togglePrompt={this.props.togglePrompt}
                toggleModal={this.props.toggleModal}
                reviewsVisible={featuresVisible}
                installUrl={this.props.installUrl}
                isChrome={this.props.isChrome}
              />
            </div>
          )
        }}
      />
    )
  }
}

export default Contents
