/*
 Add pages in this object
 All carousel images should go into the respective folder in the Drive
 Add custom static reviews in src/static-reviews/staticReviews.js
*/
const pages = {
  cards: {
    gameName: "",
    descriptionText:
      "Play Cards games on India's most loved real money entertainment platform. 10000+ players are playing every day. Choose your contest / table, small stakes or big stakes. Unlimited Real Cash to be won!",
    descriptionList: [
      "Discover India's biggest cards tables ♠️ ❤️",
      "Play with sirf 100% verified players, no fake profiles 👩 👨",
      "Choose stakes apni marzi ke - small, medium, large, & HUGE 🏆",
      "Khelo kabhi bhi, with non-stop 24-hours tables ⏳",
      "Kisi bhi issue ke liye high-priority chat support 👨🏽‍💻",
      "Support karo 100% made in India app 🇮🇳",
    ],
    popupImage: "poker-girl.png",
    popupTitle: "Riya won ₹89,580 first week mein!",
    popupText:
      "Play high stakes poker to win bahut jyada cash. Next tournament starts in:",
    vimeoVideoId: "537657601",
    gtagId: "AW-718511341",
    airtableName: "Poker LP Reviews",
    airtableBaseId: "appNqwhCawaU6zPpv",
    staticReviews: "cards",
    ratingCount: "4.7",
    totalPlayerCount: "3.2",
    totalPlayerCountIn: "Lakh",
    totalPrizeCount: "20",
    downloadButtonText: "Download App",
    disclaimerText:
      "This game involves an element of financial risk and may be addictive. Please play responsibly and at your own risk.",
    variants: {
      default: {
        installUrl: "https://getmega.com/latest",
        forceUse: true,
        hostingSite: "download-affiliate-cards",
        taboolaPixelTracking: true,
        outbrainPixelTracking: true,
        fbPixelTracking: true,
      },
      phonepe: {
        installUrl:
          "https://mega-prod-assets.getmega.com/app-releases/7.0.0/Mega-280-7.0.0.apk",
        forceUse: true,
        hostingSite: "download-phonepe-app-cards",
        taboolaPixelTracking: true,
        outbrainPixelTracking: true,
        fbPixelTracking: true,
        phonepePage: true,
        descriptionText:
          "Play Mega Poker game on India’s most loved real money entertainment platform. 10000+ players are playing every day. Choose your contest / table, small stakes or big stakes. Unlimited Real Cash to be won!",
        carouselImages: ["cards-phonepe/1.jpg"],
      },
    },
  },
  rummy: {
    gameName: "rummy",
    descriptionText:
      "Play Rummy game online | 0 waiting period | Win cash prizes upto 20L daily | Unlimited winnings withdrawals through UPI | Enjoy different variants of Rummy",
    descriptionList: [
      "Discover India's biggest Rummy tables ♠️ ❤️",
      "Play with sirf 100% verified players, no fake profiles 👩 👨",
      "Choose stakes apni marzi ke - small, medium, large, & HUGE 🏆",
      "Khelo kabhi bhi, with non-stop 24-hours tables ⏳",
      "Kisi bhi issue ke liye high-priority chat support 👨🏽‍💻",
      "Support karo 100% made in India app 🇮🇳",
    ],
    customCarousel: true,
    popupImage: "rummy-girl.png",
    popupTitle: "Don't miss out on your chance to win big!",
    popupText: "Get 500% bonus cash on your 1st deposit.",
    vimeoVideoId: "460421190",
    gtagId: "AW-718511341",
    gaTag: "G-LLL1MHMZF9",
    airtableName: "Poker LP Reviews",
    airtableBaseId: "appzs5lZNAcpHlhyA",
    staticReviews: "rummy",
    ratingCount: "4.7",
    totalPlayerCount: "50",
    totalPlayerCountIn: "Lakh",
    totalPrizeCount: "20",
    downloadButtonText: "Download App",
    disclaimerText:
      "This game involves an element of financial risk and may be addictive. Please play responsibly and at your own risk.",
    disclaimerBottom:
      "Disclaimer: Mega Rummy is the biggest online rummy gaming app in India with different variants of rummy and zero waiting period. Mega Rummy is only available for people who are above 18 years of age. Mega Rummy is available only in those Indian states where skill gaming is allowed by regulations. Megashots Internet Pvt. Ltd. is the sole owner of and reserves the right to “Mega” trademark, logos, assets, content, information, etc. used in the website.",
    hasLargeDescription: false,
    makeReviewsCollapsable: true,
    gameCategory: "rummy",
    showGlPokerScript: false,
    largeDescription: `
        <p>Mega Rummy offers great rewards. Whether you're a beginner trying your hands on rummy online, or a cash rummy enthusiast wanting to test your skills, Mega Rummy is the app you're looking for!</p>
        <ul>
        <li>Play rummy card games 24x7</li>
        <li>Win real cash prizes daily</li>
        <li>50 million+ players</li>
        <li>The Most Trusted Rummy Site®</li>
        <li>Instant withdrawals: 100% safe & secure cash rummy online withdrawal</li>
        </ul>
        <p>Online Rummy / Rammy (रमी/रम्मी) is a popular card game played in India. It is played with 2 to 6 players and a deck of cards. The objective of the game is to form sets and run with the cards in hand, and be the first player to dispose of all cards.<br />Mega offers a seamless experience to every player, be it a player who downloaded their first rummy game or someone who regularly plays rummy online. Right from the rewards to the gameplay experience.</p>
        <p>As one of the leading rummy game apps in the country, Mega Rummy offers different formats of the game to its players. This includes Points rummy, Pool rummy, Deals Rummy & tournaments.</p>
        <ul>
        <li><strong>Points rummy</strong> - Points rummy is the quickest and the most exciting variant of rummy. It can be played between 2 to 6 players with the player reaching 0 points first winning the game. The amount won depends on the points accumulated by the opponents. You can play from ₹0.05 to ₹20 Point value games on Mega</li>
        <li><strong>Deals rummy</strong> - Objective in this game is to reach 0 points as quickly as possible as well. The only difference is that while Points rummy is played for just 1 round or deal, Deals rummy goes on for multiple pre-decided rounds.</li>
        <li><strong>Pool rummy</strong> - Format that's based on the 'last player standing' idea. There are different variants within this format - 51, 101 and 201.</li>
        </ul>
        <h3>Unique features of Mega Rummy</h3>
        <ul>
        <li>Get all rummy game variants to choose from- play points, pool 101, pool 201, and  
        Deals Rummy.</li>
        <li>Zero waiting time on all the rummy tables.</li>
        <li>Our platform supports all payment modes, including UPI & bank transfer.</li>
        <li>Send unlimited rummy game referrals to as many friends as you want.</li>
        <li>Unlimited Instant cash withdrawals.</li>
        <li>Get FREE unlimited practice chips to learn how to play rummy.</li>
        <li>Get 24x7 chat support in both English and Hindi.</li>
        <li>VIP players get to request support on call.</li>
        </ul>
        <h3>Offers And Rewards</h3>
        <ul>
        <li>Best deposit offers in Industry: Upto 8X</li>
        <li>Win up to 25000 INR through referrals</li>
        <li>Enter the leaderboards with NO ENTRY FEES</li>
        <li>Win rewards by leading the leaderboard</li>
        <li>Use the code NEW400 and get 400% cashback</li>
        </ul>
        <h3>Responsible gaming & Fair Play on Mega Rummy -</h3>
        <p>Mega Rummy has a best-in-class Responsible gaming mechanism for its players.Our platform is equipped with state-of-the-art security measures to ensure the safety of your personal and financial information. Trust us, your money and data are in safe hands.</p>
        <ul>
        <li>Only players who are 18+ years of age are allowed to play.</li>
        <li>SSL secured Safe, secure, verified payment channels to ensure smooth cash deposit & withdrawal.</li>
        <li>RNG certification ensures that shuffling of the cards is purely random.</li>
        <li>All games played on Mega Rummy are checked for Fair Play protection.</li>
        <li>Regular ever-updating Fairplay policy with stricter measures along with player monitoring.</li>
        <li>Customer support for all players for smooth & faster resolution to help resolve players’ issues faster.</li>
        </ul>
        <p>Download Mega Rummy, a gaming app for winning real money, and win daily cash prizes while enjoying thrilling Rummy games.</p>
      `,
    faqsVisible: true,
    variants: {
      default: {
        installUrl: "https://www.getmega.com/rummy-adwords-lp-apk/",
        forceUse: true,
        hostingSite: "download-app8", // rummy.getmega.in
        taboolaPixelTracking: true,
        outbrainPixelTracking: true,
        fbPixelTracking: true,
        hasLargeDescription: true,
        showAppDetails: true,
        appVersion: "1.5.2",
        latestRelease: "29 Oct 2024",
        numOfDownloads: "500K+",
        appSize: "35 MB",
        imageLogo: true,
        gameCategory: "rummy",
        customDownloadLinks: true,
        appstoreLink:
          "https://apps.apple.com/in/app/mega-rummy-win-real-cash-game/id6446481991",
        playstoreLink:
          "https://play.google.com/store/apps/details?id=com.mega.app.rummy",
      },
      app8_rummy: {
        installUrl: "https://www.getmega.com/getmegarummy-lp-adwords-apk/",
        forceUse: true,
        hostingSite: "download-app8-rummy",
        taboolaPixelTracking: true,
        outbrainPixelTracking: true,
        fbPixelTracking: true,
        hasLargeDescription: true,
        gameCategory: "rummy",
      },
    },
  },
  carrom: {
    gameName: "",
    descriptionText:
      "Are you bored & alone? Do you miss playing with your friends? Don't worry, Mega Carrom is here! For the first time in the world, play Carrom on videochat & chill with your friends, in the comfort of your home. Create your private table, play live games, and hang out with your friends on videochat! 5,00,000+ players currently live on India's only social real money entertainment platform! Don't wait, videochat with your friends now!",
    descriptionList: [
      "100% safe, secure & private Carrom games for you & your friends.",
      "Talk, watch, laugh and play Carrom together on videochat in your private rooms!",
      "24-hour chat support, priority treatment for Carrom Players.",
      "Smooth experience, awesome gameplay & non-stop winnings.",
      "Seamless withdrawal of winnings to Paytm, UPI, bank account, etc.",
      "Invite friends & win real cash when they join!",
    ],
    popupImage: "carrom-girl.png",
    popupTitle: "Your friends are waiting, invite them now.",
    popupText:
      "Mat karo wait, call your friends & ho jao set. Make your Carrom room in:",
    vimeoVideoId: "537657601",
    gtagId: "AW-718511341",
    airtableName: "Poker LP Reviews",
    airtableBaseId: "appwRfYrq4MiRzouD",
    staticReviews: "carrom",
    ratingCount: "4.7",
    totalPlayerCount: "3.2",
    totalPlayerCountIn: "Lakh",
    totalPrizeCount: "20",
    downloadButtonText: "Download App",
    variants: {
      default: {
        installUrl:
          "https://affiliate_asiasouth1.getmegacdn.com/latest-carrom-new",
        forceUse: true,
        hostingSite: "download-affiliate-carrom",
        taboolaPixelTracking: true,
        outbrainPixelTracking: true,
        fbPixelTracking: true,
      },
    },
  },
  megaGames: {
    gameName: "",
    gtagId: "AW-718511341",
    airtableName: "GetMega Reviews",
    airtableBaseId: "appwRfYrq4MiRzouD",
    staticReviews: "MegaGames",
    ratingCount: "4.7",
    totalPlayerCount: "3.2",
    totalPlayerCountIn: "Lakh",
    totalPrizeCount: "20",
    variants: {
      default: {
        gameCategory: "mega",
        forceUse: true,
        hostingSite: "games-lp",
        taboolaPixelTracking: true,
        outbrainPixelTracking: true,
        fbPixelTracking: true,
      },
    },
  },
}

module.exports = pages
