import React, { useContext } from "react"
import ConfigContext from "./utils/configContext"

const DisclaimerBottom = () => {
  const context = useContext(ConfigContext)

  return (
    <div>
      <p
        style={{ padding: "0 1rem" }}
        className="has-text-grey is-size-7 margBot7"
      >
        {context.disclaimerBottom ||
          "This game involves an element of financial risk and may be addictive. Please play responsibly and at your own risk."}
      </p>
    </div>
  )
}

export default DisclaimerBottom
