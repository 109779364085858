import React from "react"
import ReviewCard from "./ReviewCard"
import { GatsbyImage } from "gatsby-plugin-image"
import StaticReviews from "./StaticReviews"
import { fetchRecords } from "../fetchAirtableRecords"
import ConfigContext from "./utils/configContext"
import DisclaimerBottom from "./DisclaimerBottom"
import "../styles/header.css"

class ReviewsList extends React.Component {
  static contextType = ConfigContext

  state = {
    reviews: [],
    reviewsByRating: {},
    showReviewsList: false,
    initialMount: true,
    showAllReviews: false,
  }

  async componentDidUpdate(newProps) {
    if (this.state.initialMount && newProps.scrollHeight > 5) {
      let { reviews, reviewsByRating } = await fetchRecords(
        this.context.airtableName,
        this.context.airtableBaseId
      )
      this.setState({
        reviews,
        reviewsByRating,
        showReviewsList: true,
        initialMount: false,
      })
    }
  }

  toggleAllReviews = () => {
    this.setState(state => ({ showAllReviews: !state.showAllReviews }))
  }

  render() {
    let { reviews, showReviewsList, showAllReviews } = this.state

    let { gameName, data } = this.props
    let reviewElements = reviews.map((review, index) => {
      return (
        <div key={index}>
          <ReviewCard review={review} />
        </div>
      )
    })
    const showAllOrLess = showAllReviews ? "all" : "less"

    const language = this.context.language

    const readMoreText = this.context.readMoreText || "SEE ALL REVIEWS"
    const collapseText = this.context.collapseText || "COLLAPSE"

    const showMoreReviews =
      showAllReviews && showReviewsList ? reviewElements : null
    return (
      <>
        <hr />
        <div
          className={`container reviews-container ${
            !this.context.makeReviewsCollapsable && "reviewBottomPadding"
          }`}
        >
          <div id="reviews">
            {this.context.makeReviewsCollapsable ? (
              <>
                <StaticReviews
                  reviewType={gameName}
                  reviewCount={showAllOrLess}
                />
                {showMoreReviews}
              </>
            ) : (
              <>
                <StaticReviews reviewType={gameName} reviewCount="5" />
                {!language && (showReviewsList ? reviewElements : null)}
              </>
            )}
          </div>
          {this.context.makeReviewsCollapsable && (
            <div className="toggle-btn-wrap">
              <span
                onClick={this.toggleAllReviews}
                onKeyDown={this.toggleAllReviews}
                role="button"
                tabIndex="0"
                className="is-capitalized playColor has-text-weight-semibold is-size-7 is-pointer"
              >
                {showAllReviews ? collapseText : readMoreText}
              </span>
            </div>
          )}
        </div>
        {this.context.faqsVisible && (
          <>
            <div className="faqs-wrap container">
              <h4>FAQs</h4>
              <div className="faqs-item">
                <h5>1. Is rummy legal to play in India?</h5>
                <div className="faqs-content">
                  <p>
                    Yes, the Supreme Court of India has declared rummy a skill
                    game and ruled that playing a skill game like rummy for real
                    money is a business activity, not betting or gambling.
                    Therefore, it is absolutely legal to play online rummy for
                    both cash and for free in India.
                  </p>
                </div>
              </div>
              <div className="faqs-item">
                <h5>
                  2. What rummy game variants are available on Mega Rummy?
                </h5>
                <div className="faqs-content">
                  <p>
                    Mega Rummy has all the variants of rummy like Points Rummy,
                    Pool Rummy (51, 101 and 201 Pool) and Deals Rummy (Best of
                    2, Best of 3).
                  </p>
                </div>
              </div>
              <div className="faqs-item">
                <h5>3. How do you sign up for Mega Rummy?</h5>
                <div className="faqs-content">
                  <p>
                    1. Download the Mega Rummy app onto your mobile phone.
                    <br />
                    2. Enter your mobile number and click on "Register for
                    Free". That's it!
                  </p>
                </div>
              </div>
              <div className="faqs-item">
                <h5>4. Is the Mega Rummy app free or paid?</h5>
                <div className="faqs-content">
                  <p>
                    The Mega Rummy app is free to download and play on mobile.
                    You can play rummy online for free by engaging in unlimited
                    practice games on the app. You can also play for cash by
                    paying a small entry fee, as low as Rs. 1, in cash games or
                    tournaments.
                  </p>
                </div>
              </div>
              <div className="faqs-item">
                <h5>5. How to withdraw cash from the app?</h5>
                <div className="faqs-content">
                  <p>
                    You can easily withdraw the winning amount by placing a
                    request for cash withdrawal and then enter the amount you
                    wish to withdraw and the amount will be credited to your
                    bank account, instantly.
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="payments-partner">
          <div className="payments-header">Our Payment Partners</div>
          <div className="partners-image">
            <GatsbyImage
              image={data.partners.childImageSharp.gatsbyImageData}
              alt=""
            />
          </div>
        </div>
        <DisclaimerBottom />
        <div className="extra-review"></div>
      </>
    )
  }
}

export default ReviewsList
