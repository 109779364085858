import React from "react"
import ReviewCard from "./ReviewCard"
import { getStaticReviews } from "../static-reviews/staticReviews"
import ConfigContext from "./utils/configContext"

export default class StaticReviews extends React.Component {
  static contextType = ConfigContext

  render() {
    let { reviewCount, reviewType } = this.props

    const language = this.context.language

    const sliceSize = reviewCount === "less" ? 3 : 99
    return (
      <>
        {getStaticReviews(reviewType, language)
          .slice(0, sliceSize)
          .map((review, index) => {
            return (
              <div key={index}>
                <ReviewCard review={review} className="marg16" />
              </div>
            )
          })}
      </>
    )
  }
}
